@import '../assets/theme';

.form-header {
  display: inline-block;
  font-size: 1.125rem;
  color: $dark;
  width: 100%;
  border-bottom: 2px solid $medium;
  padding-bottom: 5px;
  margin-bottom: 15px;
}
@import '../assets/theme';

.table {
  display: flex;
  flex-wrap: wrap;
  font-size: 0.875rem;
}

.row {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.row-container {
  width: 100%;
  padding: 15px;
}

.header {
  font-family: $font-family-sans-serif-medium;
  font-weight: bold;
}

.header-collapsed {
  white-space: nowrap;
}

.cell {
  &:not(:first-child) {
    margin-left: 10px;
  }
}

.cell-value {
  text-align: right;
  word-break: break-all;
}

.col-noheader {
  color: $dark;
  font-size: 1.125rem;
  padding-bottom: 15px;
}

@import '../assets/theme';

.container {
  display: flex;
  padding: 1rem;
  border-radius: 10px;
  margin-bottom: 1rem;

  &.warning {
    color: $danger;
    background-color: #fdf4f5;
  }

  &.info {
    color: $info;
    background-color: lighten($info, 65%);
  }

  .message > ul {
    list-style: none;
    padding-left: 0;
    margin: 0;
  }

  &:not(.vertical) {
    .icon {
      width: 24px;
      height: 24px;
      align-self: flex-start;
    }

    .message {
      margin: 0 0 0 5px;
    }
  }

  &.vertical {
    flex-direction: column;
    align-items: center;

    .icon {
      width: 36px;
      height: 36px;
    }

    .message {
      margin: 5px 0 0 0;
      text-align: justify;
    }
  }
}

@import '../../assets/theme';

.hero {
  color: $primary;
  padding: 0 !important;
  background-color: $light;
  margin-bottom: 0;
  overflow: hidden;

  .copy {
    padding: 3rem 2rem;
  }

  .image {
    background: bottom no-repeat
      url('../../assets/images/home-hero-bg-xs-290h.jpg');
    height: 290px;
    margin-bottom: 0 !important;
  }

  @include media-breakpoint-up(md) {
    height: 375px;

    .copy {
      padding: 10px;
      //background-color: rgba($light, 0.25);
    }

    .image {
      background: right/100% no-repeat
        url('../../assets/images/home-hero-bg-md-860h.jpg');
      height: 100%;
      margin-top: 0;
    }
  }
}

@import '../assets/theme';

.pager-btn,
.pager-num {
  @include button-reset;
  cursor: pointer;
  margin-right: $grid-gutter-width;

  &[disabled] {
    color: $medium;
    cursor: auto;
  }
}

.pager-btn {
  transition: color 0.5s ease;
}

.pager-num {
  min-width: 2em;
  min-height: 2em;
}

$icomoon-font-family: "cust-portal" !default;
$icomoon-font-path: "fonts" !default;

$icon-minus-circle: "\e90f";
$icon-minus-circle-fill: "\e910";
$icon-plus-circle: "\e900";
$icon-plus-circle-fill: "\e901";
$icon-email: "\e903";
$icon-phone: "\e904";
$icon-tooltip: "\e905";
$icon-play: "\e906";
$icon-close: "\e907";
$icon-check-circle-path1: "\e908";
$icon-check-circle-path2: "\e909";
$icon-check: "\e90a";
$icon-arrow-left: "\e90b";
$icon-arrow-right: "\e90c";
$icon-arrow-up: "\e90d";
$icon-arrow-down: "\e90e";
$icon-chezburger: "\e902";


@import '../assets/theme';

.tab-container {
  position: relative;
  overflow-x: scroll;
  white-space: nowrap;
  margin-right: -$spacer * 1.5;
  scroll-behavior: smooth;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  .tab-item {
    display: inline-block;
    margin-right: $spacer * 2.5;

    .badge {
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: 1.2rem;
      border-radius: 0.6rem;
      padding-left: 0;
      padding-right: 0;
    }

    button {
      @include button-reset;
      color: scale-color($primary, $alpha: -50%);
      font-size: $font-size-base;
      font-family: $font-family-sans-serif-medium;
      transition: color 0.5s ease, border-color 0.5s ease, left 1s ease;
      padding-bottom: 10px;
    }

    &.active button {
      color: $primary;
      border-bottom: 4px solid $primary;
    }
  }
}

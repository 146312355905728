@import "icon-variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?r6df9p');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?r6df9p#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?r6df9p') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?r6df9p') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?r6df9p##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-minus-circle {
  &:before {
    content: $icon-minus-circle; 
  }
}
.icon-minus-circle-fill {
  &:before {
    content: $icon-minus-circle-fill; 
  }
}
.icon-plus-circle {
  &:before {
    content: $icon-plus-circle; 
  }
}
.icon-plus-circle-fill {
  &:before {
    content: $icon-plus-circle-fill; 
  }
}
.icon-email {
  &:before {
    content: $icon-email;
  }
}
.icon-phone {
  &:before {
    content: $icon-phone;
  }
}
.icon-tooltip {
  &:before {
    content: $icon-tooltip;
  }
}
.icon-play {
  &:before {
    content: $icon-play;
  }
}
.icon-close {
  &:before {
    content: $icon-close;
  }
}
.icon-check-circle .path1 {
  &:before {
    content: $icon-check-circle-path1;  
    color: rgb(0, 47, 135);
  }
}
.icon-check-circle .path2 {
  &:before {
    content: $icon-check-circle-path2;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-check {
  &:before {
    content: $icon-check;
  }
}
.icon-arrow-left {
  &:before {
    content: $icon-arrow-left;
  }
}
.icon-arrow-right {
  &:before {
    content: $icon-arrow-right;
  }
}
.icon-arrow-up {
  &:before {
    content: $icon-arrow-up;
  }
}
.icon-arrow-down {
  &:before {
    content: $icon-arrow-down;
  }
}
.icon-chezburger {
  &:before {
    content: $icon-chezburger;
  }
}


@import '../assets/theme';

.toggler {
  padding: 10px 10px;
  border: none;
  color: $primary;
  padding: $navbar-toggler-padding-y $navbar-toggler-padding-x;
  line-height: 1;
  background-color: transparent;

  @include font-size($navbar-toggler-font-size);
  @include border-radius($navbar-toggler-border-radius);

  @include hover-focus() {
    text-decoration: none;
    outline: none;
  }
}

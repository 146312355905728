@use "sass:math";

@import '../assets/theme';

a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

.nav-item {
  line-height: $line-height-lg;
  font-size: $font-size-lg;
  font-family: $font-family-sans-serif-bold;
  position: relative;
  white-space: nowrap;

  @include media-breakpoint-down(xs) {
    border-top: 1px solid gray('300');
    margin-left: -$grid-gutter-width;
    margin-right: -$grid-gutter-width;

    &:nth-child(1) {
      margin-top: 10px;
    }
  }

  @include media-breakpoint-up(md) {
    margin-left: math.div($grid-gutter-width, 3);
  }

  a,
  a.nav-link {
    color: theme-color('primary') !important;
    text-decoration: none !important;

    &:hover {
      color: theme-color('secondary') !important;
      text-decoration: none !important;
    }

    &.active {
      @include media-breakpoint-up(sm) {
        margin-left: 28px;
      }
      &::before {
        content: '';
        width: 10px;
        height: 10px;
        background-color: $secondary;
        border-radius: 10px;
        position: absolute;
        top: 42%;

        @include media-breakpoint-up(xs) {
          left: $grid-gutter-width;
        }

        @include media-breakpoint-up(sm) {
          left: $grid-gutter-width - math.div($grid-gutter-width, 6);
        }
      }
    }
  }
}

@use "sass:math";

@import '../assets/theme';

.section-title {
  font-family: $font-family-sans-serif-medium;
  font-size: 1.125rem;
  padding: 8px 8px 4px math.div($grid-gutter-width, 2);
}

.container {
  font-size: 1rem;
  margin-bottom: 1.5rem !important;

  .legend {
    margin-bottom: 1.5rem;
    background-color: $primary;
    color: $white;
  }

  label {
    font-weight: $font-weight-bold;
  }

  :global .row {
    margin: 0 0;
  }
}

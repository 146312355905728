@import '@/assets/theme';

.hero {
  height: 400px;
  background-color: $light;
  background-size: cover !important;
  background-repeat: no-repeat;
  padding: 0 !important;
}

.copy-container {
  position: relative;
  overflow: hidden;
  height: 100%;
}

.copy {
  top: -2.5rem;
  height: 30rem;
  background-color: adjust-color($secondary, $alpha: -0.1);
  border-top-right-radius: 15em;
  border-bottom-right-radius: 15em;

  h3,
  h5 {
    color: white;
  }
}

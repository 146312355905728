﻿//
// Mixins
//

// Replace `$search` with `$replace` in `$string`
// Used on our SVG icon backgrounds for custom forms.
//
// @author Hugo Giraudel
// @param {String} $string - Initial string
// @param {String} $search - Substring to replace
// @param {String} $replace ('') - New value
// @return {String} - Updated string
@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace +
      str-replace(
        str-slice($string, $index + str-length($search)),
        $search,
        $replace
      );
  }

  @return $string;
}

// Characters which are escaped by the escape-svg function
$escaped-characters: (
  ('<', '%3c'),
  ('>', '%3e'),
  ('#', '%23'),
  ('(', '%28'),
  (')', '%29')
);

// See https://codepen.io/kevinweber/pen/dXWoRw
@function escape-svg($string) {
  @if str-index($string, 'data:image/svg+xml') {
    @each $char, $encoded in $escaped-characters {
      // Do not escape the url brackets
      @if str-index($string, 'url(') == 1 {
        $string: url('#{str-replace(str-slice($string, 6, -3), $char, $encoded)}');
      } @else {
        $string: str-replace($string, $char, $encoded);
      }
    }
  }

  @return $string;
}

//
// Color scheme
//

$primary: #002f87;
$secondary: #00aaf4;
$danger: #c00016;
$light: #f2f6f7;
$dark: #202020;
$info: #028399;
// Others: success, warning
$medium: #b9c9d2;
$danger-bg: #fdf4f5;
$danger-border: #eb959f;

$white: #fff;
$black: #000;

$gray-600: #6c757d;
$gray-700: #455051;

//
// Base variables
//

$body-color: $dark;
$headings-color: $primary;
$border-radius: 8px;
$border-radius-lg: $border-radius;
$border-radius-sm: $border-radius;

$box-shadow-inset-b-sm: inset 0 -0.125rem 0.25rem rgba($black, 0.075);
$box-shadow-inset-b: inset 0 -0.5rem 1rem rgba($black, 0.15);
$box-shadow-inset-b-lg: inset 0 -1rem 3rem rgba($black, 0.175);

//
// Animation
//

$transition-fast: 0.15s;
$transition-medium: 0.25s;
$transition-slow: 0.5s;

//
// Grid
//

$grid-columns: 18;

//
// Type
//

$line-height-md: 2;
$line-height-lg: 3;
$font-size-base: 1rem; // Assumes the browser default, typically `16px`
$font-size-lg: $font-size-base * 1.25; // +4px
$font-size-xl: $font-size-base * 1.5; // +8px
$font-size-xxl: $font-size-base * 2; // +16px
$font-size-xxxl: $font-size-base * 2.5; //24px;
$font-size-huge: $font-size-base * 3; // +32px
$font-size-sm: $font-size-base * 0.875; // -2px

$font-system-stack: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
  'Helvetica Neue', Arial, 'Noto Sans', sans-serif;
$font-family-sans-serif: 'cerapro-regular', $font-system-stack;
$font-family-sans-serif-bold: 'cerapro-bold', $font-system-stack;
$font-family-sans-serif-medium: 'cerapro-medium', $font-system-stack;

//
// Spacing
//

$spacer: 1rem;
$spacers: (
  6: (
    $spacer * 6,
  ),
  7: (
    $spacer * 10,
  ),
);

//
// Links
//

$link-decoration: underline;
$link-hover-color: $primary;

//
// Navbar toggler
//

$navbar-toggler-padding-y: 10px;
$navbar-toggler-padding-x: 10px;
$navbar-light-color: $primary;
$navbar-light-toggler-border-color: transparent;

//
// Jumbotron
//

$jumbotron-bg: $light;

//
// Input + Buttons
//

$input-btn-padding-y: 1rem;
$input-btn-padding-x: 1rem;

// Buttons
$btn-padding-x: 3rem;
//$btn-padding-y: 4rem;
$btn-font-family: $font-family-sans-serif-bold;
$btn-border-radius: 30px;
$btn-border-radius-lg: 30px;
$btn-border-radius-sm: 10px;
$btn-line-height: 1.1;
$btn-transition: all 0.15s ease-in-out; //, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;

// Input
$input-bg: $light;
// $input-padding-y: 1rem;
// $input-padding-x: 1rem;
$input-font-size: $font-size-sm;
$input-border-radius: 0;
$input-border-radius-lg: 0;
$input-border-radius-sm: 0;
$input-focus-border-color: $primary;
$input-focus-box-shadow: none;

// Custom Input
$component-active-color: $secondary;
$component-active-bg: $light;

//$custom-control-indicator-size: 1.2rem;
$custom-control-indicator-border-color: $medium;
$custom-control-indicator-checked-border-color: $medium;
$custom-control-indicator-disabled-bg: $light;
$custom-control-indicator-checked-disabled-bg: $light;
$custom-control-indicator-active-bg: darken($light, 15%);
$custom-control-indicator-active-border-color: $medium;
$custom-control-indicator-focus-border-color: $medium;
$custom-control-label-disabled-color: $medium;

// Custom checkbox
$custom-checkbox-indicator-border-radius: 0;

// Custom select
$custom-select-bg: $light;
$custom-select-padding-y: 1rem;
$custom-select-padding-x: 1rem;
$custom-select-border-radius: 0;
$custom-select-bg-size: 8px 10px !default; // In pixels because image dimensions
$custom-select-font-size: $font-size-sm;
$custom-select-indicator-color: $gray-700;
$custom-select-indicator: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='19' height='10' viewBox='0 0 19 10'><path d='M1.128,18.8A.641.641,0,0,1,.66,19a.639.639,0,0,1-.467-.2.68.68,0,0,1,0-.95L8.406,9.5.193,1.147a.68.68,0,0,1,0-.95.653.653,0,0,1,.934,0L9.807,9.024a.68.68,0,0,1,0,.95Z' transform='translate(19) rotate(90)' fill='#{$custom-select-indicator-color}'/></svg>");
$custom-select-background: escape-svg($custom-select-indicator) no-repeat right
  1rem bottom 50%;

// Popover
$popover-max-width: 350px;
$popover-border-radius: none;
$popover-border-width: 0;
$popover-inner-border-radius: none;
$popover-header-bg: $white;
$popover-header-color: $info;
$popover-header-padding-x: 1rem;
$popover-box-shadow: 0 1rem 0.5rem rgba($black, 0.2);

// Input feedback
$form-feedback-font-size: 0.9em;
$form-feedback-icon-valid: none;
$form-feedback-icon-invalid: none;

//
// Mixins
//

@mixin rgba-box-shadow(
  $offsetX,
  $offsetY,
  $blurRadius,
  $spreadRadius,
  $hexcolor,
  $opacity
) {
  box-shadow: $offsetX $offsetY $blurRadius $spreadRadius
    rgba($hexcolor, $opacity);
}

@mixin button-reset {
  display: inline-block;
  border: none;
  margin: 0;
  padding: 0;
  text-decoration: none;
  background: transparent;
  color: $body-color;
  font-family: $font-family-sans-serif;
  font-size: $font-size-base;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  -webkit-appearance: none;
  -moz-appearance: none;

  &:focus {
    outline: none;
  }
}

// Required
@import '../../node_modules/bootstrap/scss/functions';
@import '../../node_modules/bootstrap/scss/variables';
@import '../../node_modules/bootstrap/scss/mixins';
@import './icon-variables';

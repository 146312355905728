@import '../assets/theme';

.flow-container {
  display: flex;
  justify-content: space-between;

  .flow-item {
    padding: 0.8rem 0;
    width: 100%;
    cursor: default;
    text-align: center;

    .flow-item-caption {
      display: none;
      font-size: $font-size-sm;
      font-family: $font-family-sans-serif-medium;
    }

    .flow-item-num {
      height: 30px;
      width: 30px;
      line-height: 2;
      margin: auto;
      text-align: center;
      font-size: $font-size-sm;
      color: $medium;
      background-color: $light;
      user-select: none;
      border: 1px solid $medium;
      border-radius: 15px;
    }

    &.active,
    &.completed {
      border-bottom: 3px solid $secondary;

      .flow-item-num {
        border: 1px solid $secondary;
      }
    }

    &.active {
      .flow-item-num {
        color: $white;
        background-color: $secondary;
      }
    }

    &.completed {
      cursor: pointer;

      .flow-item-num {
        //padding-left: 0.4rem;
        color: $secondary;
        background-color: transparent;
        //cursor: pointer;

        & > i {
          font-size: 0.6rem;
        }
      }
    }
  }

  // On md and up viewports, we display the flow item captions.
  @include media-breakpoint-up(md) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    .flow-item {
      // padding-left: 10px;
      // padding-right: 10px;
      white-space: nowrap;

      .flow-item-num {
        display: inline-block;
        margin-right: 10px;
      }

      .flow-item-caption {
        display: inline-block;
        color: $medium;
      }

      &.active {
        .flow-item-caption {
          color: $dark;
        }
      }

      &.completed {
        .flow-item-caption {
          color: $dark;
        }
      }
    }
  }
}
